<template>
	<div class="container">

		<div class="kontakt">
			<div>
<div>

	<div>Marianne Fast: +43 664 / 790 37 63</div>
	<div>Peter Pauritsch: +43 676 / 300 95 46</div>
<div>Rudi Fast: +43 699 / 100 548 98</div>
<div><a href="mailto:marianne.fast@viridarium.at">E-mail: marianne.fast@viridarium.at</a></div>

 

</div>
			</div>

		</div>
	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'Kontakt',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
			}
		}, // end data
		methods: {


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

// #mainCont {
// 	background-color: #EEE472;
// 	height: 34rem;

// 	background-image: url('/img/Viridarium_Gartengestaltung_Beratung_Planung_Schaugarten_Ahorn_Herbstlaub_g.jpg');

//   background-color: #cccccc; /* Used if the image is unavailable */
//   height: 500px; You must set a specified height
//   background-position: center; /* Center the image */
//   background-repeat: no-repeat; /* Do not repeat the image */
//   background-size: cover; /* Resize the background image to cover the entire container */
// 	opacity: 0.75;
// }


#wrapper {
	height: 100% !important;
}
.container {
	height: 100%;
	display: flex;
	justify-content: center;
	// align-items: center;
	margin-top: 3rem;

}

.kontakt {

    width: 53rem;
    height: 12rem;
    align-items: center;
    // background-image: url("/sys_img/bg_05.jpg");
    background-color: #eee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #444;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px 3px #b9b9b9;
	a {
		text-decoration: none;
    color: #444;

		&:hover {
			color: var(--c11);
		}
	}

	& > div	{
		flex: 0 0 46%;

		& > div {

			font-family: f2;
			font-size: 1.06rem;
			font-weight: bold;

		}

		// border: 1px solid silver;
	}

}

@media (max-width: 600px) {
	.kontakt {
			& > div	{
		flex: 0 0 88%;
	}

	}
}

</style>
